import * as config from './../../configs/s3.json';

export const environment = {
  production: false,
  config: config
}

/* tslint:disable */
console.info(config)
/* tslint:enable */
